import { Progress, Typography } from "@material-tailwind/react";

export default function SkillsProgres(props) {

    return (
        <>
            <div className="mb-2 flex items-center justify-between gap-4">
                <Typography  variant="h6">
                    <span>{props.progresName}</span>
                </Typography>
                <Typography variant="h6">
                    <span>{props.progresPercent}</span>
                </Typography>
            </div>
            <Progress className="progres bg-nav_text w-full h-[6px]" value={props.progresValue} />
        </>
    )

}
