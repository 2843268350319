import BlocksDesc from "./blocksdesc";
import {BiRightArrowAlt } from "react-icons/bi"

export default function ServiceBlocks(props) {
    return (
        <>
            <div id={props.id} className={`${props.class}  -translate-x-56 opacity-0 w-full md:w-1/2 lg:w-1/4 max-lg:mt-5 md:px-3.5`}>
                <div className="group bg-blocks_light_bg rounded-[6px] space-y-4 p-[50px] lg:p-[28px] xl:p-[50px] ">
                    <div className="w-[50px] h-[50px]">
                        <img className=" " src={props.img} alt="" />
                    </div>
                    <h4 className="text-[26px] text-white font-poppins font-semibold">{props.title}</h4>
                    <div className="flex flex-row items-center space-x-4">
                        <span className="text-nav_text group-hover:text-red duration-300 font-poppins text-[18px] leading-7  lg:text-[18px " >Learn More</span>
                        <p className="relative flex justify-center items-center  text-nav_text text-xl group-hover:text-red  duration-200 after:content-[''] after:absolute after:border after:border-nav_text after:left-3 after:duration-300 after:w-0 group-hover:after:border-red group-hover:after:w-5">
                         <span className="group-hover:translate-x-5 duration-200"><BiRightArrowAlt /></span>   </p>
                    </div>



                </div>


            </div>
        </>
    )

}