import React, { useEffect } from 'react';
import BigTitles from "../../PropsComponents/bigTitles";
import BlocksDesc from "../../PropsComponents/blocksdesc";
import NewsBlocks from "../../PropsComponents/newsBlocks";
import RedTitles from "../../PropsComponents/redTitles";
import NewsBlocksFirstImg from "../../../assets/Img/newsblocks1.jpg";
import NewsBlocksSecondImg from "../../../assets/Img/newsblocks2.jpg";
import NewsBlocksThirdImg from "../../../assets/Img/newsblocks3.jpg";

export default function News() {

    const NewsBlocksContent = [
        {id:'FirstBlog',  class:'duration-[0.8s]', newsImg:NewsBlocksFirstImg, data:"April 07,2021", object:"Travel Landing", name:"Dostie Memorie"},
        {id:'SecondBlog', class:'duration-[1s]',  newsImg:NewsBlocksSecondImg, data:"April 07,2021 ", object:"Inspiration", name:"Chul Urina"},
        {id:'ThirdBlog',  class:'duration-[1.5s]', newsImg:NewsBlocksThirdImg, data:"April 07,2021 ", object:"Inspiration", name:"Chul Urina"},

    ];

    useEffect(() => {
        const handleScroll = () => {
            NewsBlocksContent.forEach((item) => {
                const x = document.documentElement.scrollTop;
                const newsBlogs = document.getElementById(item.id);
                if (x + 700 > newsBlogs.offsetTop) {
                    newsBlogs.classList.add('newsBlogs-transition');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div id="News" className="bg-blocks_light_bg block_padding">
                <div className="px-[20px] md:px-[40px] max-w-screen-xl space-y-10  mx-auto">
                    <div className="lg:w-3/5">
                        <RedTitles redtitle="Blogs" />
                        <BigTitles bigTitle="Recent News" />
                        <BlocksDesc BlocksDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, egestas. Id fermentum nullam ipsum massa." />
                    </div>
                    <div className="lg:flex lg:space-x-8 max-lg:space-y-20">
                        {
                            NewsBlocksContent.map((item, index)=>
                            {
                                return <NewsBlocks id={item.id} class={item.class} newsImg={item.newsImg} data={item.data} object={item.object} name={item.name}/>
                            })
                        }
                        
                    </div>
                </div>
            </div>
        </>
    )
}