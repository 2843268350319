import React from "react";
import { TypeAnimation } from 'react-type-animation';

export default function Typing() {

    return (
        <>
            <TypeAnimation 
                className="text-[31px] md:text-[50px]  lg:text-[70px] font-bold duration-200"
                sequence={[
                    // Same substring at the start will only be typed out once, initially
                    'Visual Artist',
                    1000, // wait 1s before replacing "Mice" with "Hamsters"
                    'Designer',
                    1000,
                    'Developer',
                    1000,
                    
                ]}
                wrapper="span"
                speed={20}
                style={{  display: 'inline-block', color: 'white', }}
                repeat={Infinity}
            />
        </>
    )
}