export default function Footer(){
    return (
        <>
            <div className="bg-blocks_light_bg max-lg:pt-[30px] max-lg: pb-28 lg:py-[30px]">
                <div className="flex justify-center">
                    <span className="text-white text-[16px] leading-8">© 2023 by ib-themes. All rights reserved.</span>
                </div>

            </div>
        </>
    )
}