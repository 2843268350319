import React, { useEffect } from 'react';
import BigTitles from "../../PropsComponents/bigTitles";
import BlocksDesc from "../../PropsComponents/blocksdesc";
import RedTitles from "../../PropsComponents/redTitles";
import PortfolioFirstImg from "../../../assets/Img/portfolio1.jpg";
import PortfolioSecondImg from "../../../assets/Img/portfolio2.jpg";
import PortfolioThirdImg from "../../../assets/Img/portfolio3.jpg";
import PortfolioForthImg from "../../../assets/Img/portfolio4.jpg";
import PortfolioFifthImg from "../../../assets/Img/portfolio5.jpg";
import PortfolioSixthImg from "../../../assets/Img/portfolio6.jpg";
import PortfoliosBlocks from "../../PropsComponents/portfoliosBlocks";



export default function Portfolio() {

    const miniBlocks = [
        {
         id:'FirstPortfolio',   class: 'pl-0 duration-[1.5s]', img: PortfolioFirstImg, href: 'https://www.youtube.com/watch?v=1gyTUHP6ne8', girlsName: 'Aura Dione ', profession: 'Youtube Shoot'
        },
        {
         id:'SecondPortfolio', class:'duration-[1.5s] delay-300',  img: PortfolioSecondImg, href: 'https://player.vimeo.com/video/100171151?h=92d8f1bd0a', girlsName: 'Jupita Rine ', profession: 'Vimeo Shoot'
        },
        {
          id:'ThirdPortfolio',  class: 'pr-0 duration-[1.5s] delay-500', img: PortfolioThirdImg, href: 'https://dribbble.com/shots/16529339-Beny-Personal-Portfolio-React-Template', girlsName: 'Ave Simone ', profession: 'Dribbble Shoot'
        },
        {
         id:'ForthPortfolio',   class: 'pl-0 duration-[1.5s] ', img: PortfolioForthImg, href: 'https://player.vimeo.com/video/100171151?h=92d8f1bd0a', girlsName: 'Kelly Smith ', profession: 'Dribbble Shoot'
        },
        {
         id:'FifthPortfolio', class:'duration-[1.5s] delay-300',  img: PortfolioFifthImg, href: 'https://player.vimeo.com/video/100171151?h=92d8f1bd0a', girlsName: 'Kelly Smith ', profession: 'Dribbble Shoot'
        },
        {
         id:'Sixth',   class: 'pr-0 duration-[1.5s] delay-500', img: PortfolioSixthImg, href: 'https://player.vimeo.com/video/100171151?h=92d8f1bd0a', girlsName: 'Duru Sweet', profession: 'Dribbble Shoot'
        },


    ];

    useEffect(() => {
        const handleScroll = () => {
            miniBlocks.forEach((item) => {
                const x = document.documentElement.scrollTop;
                const portfoliosBloks = document.getElementById(item.id);
                if (x + 700 > portfoliosBloks.offsetTop) {
                    portfoliosBloks.classList.add('portfoliosBloks-transition');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div id="Portfolio" className="bg-blocks_light_bg block_padding">
                <div className=" px-[20px] md:px-[40px] max-w-screen-xl  mx-auto">
                    <div className="md:w-3/5">
                        <RedTitles redtitle='Prtfolio' />
                        <BigTitles bigTitle='My Portfolio' />
                        <BlocksDesc BlocksDesc='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, egestas. Id fermentum nullam ipsum massa.' />
                    </div>
                    <div className="flex flex-col md:flex md:flex-row md:flex-wrap">
                        {
                            miniBlocks.map((item, index) => {
                                return <PortfoliosBlocks id={item.id} class={item.class} href={item.href} img={item.img} girlsName={item.girlsName} profession={item.profession} />
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}