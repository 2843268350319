import React from "react";
import Home from "./Components/Home/home";
import About from "./Components/About/about";
import Service from "./Components/Service/service";
import Portfolio from "./Components/Portfolio/portflio";
import News from "./Components/News/news";
import Testimonials from "./Components/Testimonials/testimonials";
import Contact from "./Components/Contact/constact";

export default function Beny(){

    return(
        <>
          <Home/>
          <About/>
          <Service/>
          <Portfolio/>
          <Testimonials/>
          <News/>
          <Contact/>
        </>

    )
}