import React from "react";
import Typing from "../../PropsComponents/typing";
import Buttons from "../../PropsComponents/buttons";
import { FiFacebook, FiTwitter, FiInstagram, FiDribbble, FiLinkedin } from "react-icons/fi"
import { BsChevronDown} from "react-icons/bs"
import { Link } from "react-scroll";
import BlocksDesc from "../../PropsComponents/blocksdesc";


export default function Home() {
    const styles = {
        backgroundPositionX: "75%",
        backgroundPositionY: "0%",
    }

    return (
        <>
            <div style={styles} id="Home" className="flex  items-center min-h-screen  border relative border-black  bg-homeBG bg-cover after:contents-[''] after:absolute after:lg:opacity-0 after:opacity-80 after:w-full after:h-full after:top-0 after:left-0 after:z-20 after:bg-afterBG after:duration-500">
                <div className=" px-[20px] md:px-[40px] max-w-screen-xl  relative z-40  mx-auto ">
                    <div className="animate-home">
                        <p className="text-white text-[18px] md:text-[22px] lg:text-[26px] font-poppins">Hi! I'm Astro</p>
                        <h2><Typing /></h2>
                        <h3 className="text-[#f52225] text-[31px] md:text-[50px] lg:text-[70px] duration-150 font-bold font-poppins">Based In California.</h3>
                        <p className="w-full lg:w-2/3  "><BlocksDesc BlocksDesc="I'm developer focused on crafting user‑friendly experiences, I am passionate about building excellent software that improves the lives of those around me."/></p>
                        <div className="py-5 lg:hidden">
                            <ul className="flex  text-2xl">
                                <li className="text-white hover:text-red-600 p-2"><a href="https://www.facebook.com/"><FiFacebook /></a></li>
                                <li className="text-white hover:text-red-600 p-2"><a href="https://twitter.com/i/flow/login?redirect_after_login=%2F%3Flang%3Dru"><FiTwitter /></a></li>
                                <li className="text-white hover:text-red-600 p-2"><a href="https://www.instagram.com/"><FiInstagram /></a></li>
                                <li className="text-white hover:text-red-600 p-2"><a href="https://dribbble.com/shots"><FiDribbble /></a></li>
                                <li className="text-white hover:text-red-600 p-2"><a href="https://ru.linkedin.com/?original_referer=https%3A%2F%2Fwww.google.com%2F"><FiLinkedin /></a></li>
                            </ul>
                        </div>
                        <p className="lg:mt-4 group  hover:border-red text-white border-2 border-nav_text rounded-lg px-[35px] py-[10px] text-[19px]  h-[55px]   overflow-hidden  duration-100 w-fit  ">
                            <Buttons button="See Portfolio" />
                        </p>
                    </div>
                </div>
                <div className=" hidden lg:block absolute right-8  bottom-12">
                    <ul className="flex flex-col ">
                        <li className="group font-medium  text-nav_text font-poppins flex  text-[18px] px-3 lg:px-5 h-[50px] overflow-hidden w-fit  duration-100">
                            <a className="w-fit " href="https://www.facebook.com/">
                                <div className="mt-1 group-hover:-translate-y-11 space-y-5 duration-300">
                                    <p className="group-hover:text-red-600">FB.</p>
                                    <p className="group-hover:text-red-600">FB.</p>
                                </div>
                            </a>
                        </li>
                        <li className="group font-medium text-nav_text font-poppins flex  text-[18px] px-3 lg:px-5 h-[50px] overflow-hidden w-fit  duration-100">
                            <a className="w-fit" href="https://dribbble.com/shots">
                                <div className="mt-1 group-hover:-translate-y-11 space-y-5 duration-500">
                                    <p className="group-hover:text-red-600">DR.</p>
                                    <p className="group-hover:text-red-600">DR.</p>
                                </div>
                            </a>
                        </li>
                        <li className="group font-medium text-nav_text font-poppins flex  text-[18px] px-3 lg:px-5 h-[50px] overflow-hidden  w-fit  duration-100">
                            <a className="" href="https://www.behance.net/">
                                <div className="mt-1  group-hover:-translate-y-11 space-y-5 duration-500">
                                    <p className="group-hover:text-red-600">BE.</p>
                                    <p className="group-hover:text-red-600">BE.</p>
                                </div>
                            </a>
                        </li>
                        <li className="group font-medium text-nav_text font-poppins flex  text-[18px] px-3 lg:px-5 h-[50px] overflow-hidden w-fit  duration-100">
                            <a className="w-fit" href="https://twitter.com/i/flow/login?redirect_after_login=%2F%3Flang%3Dru">
                                <div className="mt-1 group-hover:-translate-y-11 space-y-5 duration-500">
                                    <p className="group-hover:text-red-600">TW.</p>
                                    <p className="group-hover:text-red-600">TW.</p>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="flex justify-center w-full  z-40 absolute bottom-2">
                <div >
                    <Link className=" w-9 h-11" to="About" spy={true} smooth={true} offset={-100} duration={500}>

                        <span className="bg-red animate-goAbout text-white  mx-auto flex items-center justify-center  w-[35px] h-[35px] rounded-full"><BsChevronDown/></span>
                    </Link>
                </div>
                </div>
            </div >
        </>
    )

}