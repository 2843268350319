

export default function RedTitles(props){
    return(
        <>
            <div className=" flex justify-center items-center w-fit space-x-2">
                <p className="tetx-[16px] font-poppins text-red font-medium">{props.redtitle}</p>
                <div className="h-[1px] w-[40px] bg-red"></div>
            </div>
        </>
    )
}