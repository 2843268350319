import React from "react";

export default function Buttons(props) {

    return (
        <>
            <button className="group-hover:-translate-y-9 duration-300 space-y-2">
                
                    <p className="group-hover:text-red font-medium  ">{props.button}</p>
                    <p className="group-hover:text-red font-medium ">{props.button}</p>
                

            </button>
        </>
    )


}