import React, { useEffect } from 'react';
import BigTitles from "../../PropsComponents/bigTitles";
import RedTitles from "../../PropsComponents/redTitles";
import { BsTelephoneOutbound } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { RxEnvelopeClosed } from "react-icons/rx";
import BlocksDesc from "../../PropsComponents/blocksdesc";
import Buttons from "../../PropsComponents/buttons";


export default function Contact() {

    const contactUl = [
        {
            icon: <BsTelephoneOutbound />, contact: "+44 1632 967704"
        },
        {
            icon: <RxEnvelopeClosed />, contact: "your@gmail.com"
        },
        {
            icon: <HiOutlineLocationMarker />, contact: "245 King Street, Touterie Victoria 8520 Australia"
        }
    ];


    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const contactLeft = document.getElementById('ContactLeftContent');
            if(x+700 > contactLeft.offsetTop){
                contactLeft.style.transition = 'all 0.8s ';
                contactLeft.style.transform = 'translateX(0px)';
                contactLeft.style.opacity = '1'
            }
            const contactRight = document.getElementById('ContactRightContent');
            if(x+700 > contactRight.offsetTop){
                contactRight.style.transition = 'all 1s ';
                contactRight.style.transform = 'translateX(0px)';
                contactRight.style.opacity = '1'
            }
            
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div id="Contact" className="bg-blocks_dark_bg block_padding">
                <div className="px-[20px]  lg:flex md:px-[40px] max-w-screen-xl   mx-auto">
                    <div id="ContactLeftContent" className="-translate-x-56 opacity-0 lg:w-1/2 ">
                        <RedTitles redtitle="Contact" />
                        <BigTitles bigTitle="Let's discuss your project" />
                        <ul className="pt-[20px]">
                            {
                                contactUl.map((item, index) => {
                                    return <li className="flex items-center space-x-3 mb-[30px]">
                                        <span className="text-red text-2xl">{item.icon}</span> <span className="text-xl text-nav_text">{item.contact}</span>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div id="ContactRightContent" className="-translate-x-56 opacity-0  lg:w-1/2 ">
                        <BlocksDesc BlocksDesc="I'm always open to discussing product" />
                        <h4 className="text-white font-semibold text-[25px] leading-[1.6]">design work or partnerships.</h4>

                        <form
                            action=""
                            className=" space-y-10  md:flex md:flex-wrap md:justify-between"
                        >

                            <div className="mt-5 relative h-11 w-full  min-w-[200px]">
                                <input
                                    className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal  outline outline-0 transition-all focus:border-s-main-text focus:outline-0 disabled:border-0 "
                                    placeholder=" " type="text"
                                />
                                <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none  font-normal leading-tight text-nav_text transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-main-text after:transition-transform after:duration-300  peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-nav_text peer-focus:leading-tight peer-focus:text-main-text text-main-text peer-focus:after:scale-x-100 peer-focus:after:border-main-text peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-nav_text font-poppins">
                                    Name
                                </label>
                            </div>
                            
                            <div className="mt-5 relative h-11 w-full md:w-[48%] min-w-[200px]">
                                <input
                                    className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-main-text focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                                    placeholder=" " type="text"
                                />
                                <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none  font-normal leading-tight text-nav_text transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-main-text after:transition-transform after:duration-300  peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-nav_text peer-focus:leading-tight peer-focus:text-main-text text-main-text peer-focus:after:scale-x-100 peer-focus:after:border-main-text peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-nav_text font-poppins ">
                                    Subject
                                </label>
                            </div>
                            <div className="mt-5 relative h-11 w-full md:w-[48%] min-w-[200px]">
                                <input
                                    className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-main-text focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" type="email"
                                    placeholder=" "
                                />
                                <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none  font-normal leading-tight text-nav_text transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-main-text after:transition-transform after:duration-300  peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-nav_text peer-focus:leading-tight peer-focus:text-main-text text-main-text peer-focus:after:scale-x-100 peer-focus:after:border-main-text peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-nav_text font-poppins">
                                    Email
                                </label>
                            </div>

                            <div className="mt-10  relative w-full ">
                                <textarea name="comment" id="comment" className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-main-text focus:border-b-0 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" cols="30" rows="5"></textarea>
                                <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none  font-normal leading-tight text-nav_text transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-main-text after:transition-transform after:duration-300  peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-nav_text peer-focus:leading-tight peer-focus:text-main-text text-main-text peer-focus:after:scale-x-100 peer-focus:after:border-main-text peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-nav_text font-poppins ">
                                    Message
                                </label>
                            </div>

                            <div className="group mt-5 hover:border-red text-white border-2 border-nav_text rounded-lg px-[35px] pt-[8px] text-[17px]  h-[45px] overflow-hidden w-fit  duration-100 ">
                                <Buttons button="Submit" />
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            
        </>
    )
}