


export default function NewsBlocks(props) {
    return (
        <>
            <div id={props.id} className={`${props.class} -translate-x-56 opacity-0 lg:w-1/3 h-[383px] group`}>
                <div className=" h-full  rounded-[8px] overflow-hidden">
                    <img src={props.newsImg} className=" group-hover:scale-110 max-lg:group-hover:rotate-2 lg:group-hover:rotate-6 duration-700 w-full h-full object-cover" alt="" />
                </div>
                <div className="text-[16px] font-poppins space-x-3 text-nav_text leading-8 flex items-center">
                    <p >{props.data}</p>
                    <div className="w-[5px] h-[5px] rounded-full bg-nav_text"></div>
                    <p>{props.object}</p>
                </div >

                <h4 className="text-white font-semibold font-poppins text-[22px]">{props.name}</h4>
            </div>
        </>
    )

}