import React from "react";
import { Link } from "react-scroll";
import Logo from "../../assets/Img/light.png";
import { AiOutlineHome } from "react-icons/ai";
import { FiUser, FiSettings } from "react-icons/fi";
import { FiGrid } from "react-icons/fi"
import { FaChromecast } from "react-icons/fa"
import { BsTelephoneOutbound } from "react-icons/bs"
import Buttons from "../PropsComponents/buttons";
import { useEffect, useState } from 'react';



export default function Header() {

    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const n = document.getElementById('TopNavbar');


            if (x === 0) {
                n.style.background = 'transparent';
                n.style.boxShadow = 'none';
                n.style.padding = '30px 60px'
            } else {
                n.style.transition = 'all 0.3s';
                n.style.background = '#111319';
                n.style.boxShadow = '0 0 10px hsla(0,0%,100%,.08)';
                n.style.padding = '20px 60px '
            }



        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <div id="TopNavbar" className="fixed navbar  py-[30px] px-3 lg:px-[60px]  top-0 w-full left-0 z-[100]  hidden md:block">
                <div className="   flex justify-between items-center text-xl ">
                    <div className="w-20  lg:w-24 ">
                        <Link activeClass="active" to="Home" spy={true} smooth={true} offset={-100} duration={500}>
                            <img src={Logo} className="w-full" alt="" />
                        </Link>
                    </div>
                    <div className=" ">
                        <ul className="flex   items-center font-poppins">
                            <li className="group text-nav_text  text-[17px] px-3 lg:px-4 h-[30px] overflow-hidden  duration-100">
                                <Link activeClass="active" to="Home" spy={true} smooth={true} offset={-100} duration={500}>
                                    <div className="group-hover:-translate-y-7 duration-500">
                                        <p className="group-hover:text-red">Home</p>
                                        <p className="group-hover:text-red">Home</p>
                                    </div>
                                </Link>
                            </li>
                            <li className="group text-nav_text text-[17px] px-3 lg:px-4 h-[30px] overflow-hidden  duration-100">
                                <Link activeClass="active" to="About" spy={true} smooth={true} offset={-50} duration={500}>
                                    <div className="group-hover:-translate-y-7 duration-500">
                                        <span className="group-hover:text-red">About</span> <br />
                                        <span className="group-hover:text-red">About</span>
                                    </div>
                                </Link>
                            </li>
                            <li className="group text-nav_text text-[17px] px-3 lg:px-4 h-[30px] overflow-hidden  duration-100">
                                <Link activeClass="active" to="Service" spy={true} smooth={true} offset={-100} duration={500}>
                                    <div className="group-hover:-translate-y-7 duration-500">
                                        <p className="group-hover:text-red">Service</p>
                                        <p className="group-hover:text-red">Service</p>
                                    </div>
                                </Link>
                            </li>
                            <li className="group text-nav_text text-[17px] px-3 lg:px-4 h-[30px] overflow-hidden  duration-100">
                                <Link activeClass="active" to="Portfolio" spy={true} smooth={true} offset={-100} duration={500}>
                                    <div className="group-hover:-translate-y-7 duration-500">
                                        <p className="group-hover:text-red">Portfolio</p>
                                        <p className="group-hover:text-red">Portfolio</p>
                                    </div>
                                </Link>
                            </li>
                            <li className="group text-nav_text text-[17px] px-3 lg:px-4 h-[30px] overflow-hidden  duration-100">
                                <Link activeClass="active" to="News" spy={true} smooth={true} offset={-100} duration={500}>
                                    <div className="group-hover:-translate-y-7 duration-500">
                                        <p className="group-hover:text-red">News</p>
                                        <p className="group-hover:text-red">News</p>
                                    </div>
                                </Link>
                            </li>
                            <li className="group text-nav_text text-[17px] px-3 lg:px-4 h-[30px] overflow-hidden  duration-100">
                                <Link activeClass="active" to="Contact" spy={true} smooth={true} offset={-100} duration={500}>
                                    <div className="group-hover:-translate-y-7 duration-500">
                                        <p className="group-hover:text-red">Contact</p>
                                        <p className="group-hover:text-red">Contact</p>
                                    </div>
                                </Link>
                            </li>

                            <li className="group hover:border-red text-white border-2 border-nav_text rounded-lg px-[35px] py-[5px] text-[17px]  h-[45px] overflow-hidden  duration-100 ">
                                <Link activeClass="" to="ContactUs" spy={false} smooth={true} offset={-80} duration={500}>
                                    <Buttons button="Buy Now" />
                                </Link>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className="md:hidden z-50 fixed w-full bottom-0 border border-black bg-black">
                <ul className=" flex w-full px-2 justify-between xs:justify-around">
                    <li className="py-4">
                        <Link activeClass="active1" to="Home" spy={true} smooth={true} offset={-100} duration={500}>
                            <div className="text-nav_text hover:text-red duration-200  flex flex-col items-center">
                                <p className="text-2xl" >
                                    <AiOutlineHome />
                                </p>
                                <p className="text-xs font-poppins">Home</p>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <li className="py-4">
                            <Link activeClass="active1" to="About" spy={true} smooth={true} offset={-100} duration={500}>
                                <div className="text-nav_text hover:text-red duration-200  flex flex-col items-center">
                                    <p className="text-2xl" >
                                        <FiUser />
                                    </p>
                                    <p className="text-xs font-poppins">About</p>
                                </div>
                            </Link>
                        </li>
                    </li>
                    <li className="py-4">
                        <Link activeClass="active1" to="Service" spy={true} smooth={true} offset={-100} duration={500}>
                            <div className="text-nav_text hover:text-red duration-200 flex flex-col items-center">
                                <p className="text-2xl" >
                                    < FiSettings />
                                </p>
                                <p className="text-xs font-poppins">Service</p>
                            </div>
                        </Link>
                    </li>
                    <li className="py-4">
                        <Link activeClass="active1" to="Portfolio" spy={true} smooth={true} offset={-100} duration={500}>
                            <div className="text-nav_text hover:text-red  duration-200 flex flex-col items-center">
                                <p className="text-2xl" >
                                    < FiGrid />
                                </p>
                                <p className="text-xs font-poppins">Portfolio</p>
                            </div>
                        </Link>
                    </li>
                    <li className="py-4">
                        <Link activeClass="active1" to="News" spy={true} smooth={true} offset={-100} duration={500}>
                            <div className="text-nav_text hover:text-red duration-200 flex flex-col items-center">
                                <p className="text-2xl" >
                                    <FaChromecast />
                                </p>
                                <p className="text-xs font-poppins">News</p>
                            </div>
                        </Link>
                    </li>
                    <li className="py-4">
                        <Link activeClass="active1" to="Contact" spy={true} smooth={true} offset={-100} duration={500}>
                            <div className="text-nav_text hover:text-red duration-200 flex flex-col items-center">
                                <p className="text-2xl" >
                                    <BsTelephoneOutbound />
                                </p>
                                <p className="text-xs font-poppins">Contact</p>
                            </div>
                        </Link>
                    </li>

                </ul>
            </div>
        </>
    )
}