import Footer from "./Pages/Layouts/footer";
import Header from "./Pages/Layouts/header";
import ScrollToTop from "./Pages/ScrollToTop";
import Beny from "./Pages/beny";
import Cursor from "./Pages/cursor/cursor";




function App() {
  return (
    <>
      <ScrollToTop/>

      <Header/>
      
      <Beny/>

      <Footer/>
    </>
  );
}

export default App;
