import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SliderBG from "../../assets/Img/sliderBG.svg";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SliderFirstImg  from "../../assets/Img/slider1.jpg";
import SliderSeondImg  from "../../assets/Img/slider2.jpg";
import SliderThirdImg  from "../../assets/Img/slider3.jpg";


// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import BlocksDesc from './blocksdesc';

export default function TestSlider() {


    const SliderContent=[
        {sldierImg:SliderFirstImg, sliderDesc:"Hi, I’m Alvara Atkins and I am designer &amp; developer who dream making the world better place by products. I am also very active for international clients.", name:"Alvara Atkins", profession:"Marketing Manager"},
        {sldierImg:SliderSeondImg, sliderDesc:"This is outstanding work. Everything I needed to do has been done by the makers. I really like this template and more importantly my clients are blown away by it.", name:"Fabian Gattuzo", profession:"Photographer"},
        {sldierImg:SliderThirdImg, sliderDesc:"These people really know what they are doing! Great customer support availability and supperb kindness. I am very happy that I've purchased this liscense!!!", name:"Alizee Bonita", profession:"App Developer"},
    ]

    return (
        <>
            <Swiper
                cssMode={true}
                navigation={true}
                mousewheel={true}
                keyboard={true}
                loop={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
                
            >

                {
                    SliderContent.map((item, index)=>
                    {
                        return <SwiperSlide>
                        <div className=' w-full flex  flex-col justify-center items-center lg:flex lg:flex-row'>
                            <div className=' lg:w-1/3 '>
                                <div className='max-md:w-32 md:w-[253px] md:h-[253px] rounded-[12px] overflow-hidden'>
                                    <img src={item.sldierImg} alt="" />
                                </div>
                            </div>
                            <div className='  lg:w-2/3 max-lg:space-y-5  relative '>
                                <div className='absolute top-0 left-0 z-10'>
                                    <img src={SliderBG} alt="" />
                                </div>
                                <div className='lg:pt-[20px] lg:pl-[20px] max-lg:text-center flex flex-col justify-center space-y-4'>
                                    <BlocksDesc BlocksDesc={item.sliderDesc} />
                                    <div className='flex items-center max-lg:justify-center space-x-6'>
                                        <div className='b w-[100px] max-lg:hidden'></div>
                                        <h3 className='text-[20px] md:text-[24px] font-medium font-poppins leading-[1.3]'>{item.name}</h3>
                                    </div>
                                    <p className='text-[15px] md:text-[18px] lg:pl-[123px]  font-poppins text-nav_text'>{item.profession}</p>
                                </div>
                            </div>
                        </div>
    
                    </SwiperSlide>
                    })
                }
              

            </Swiper>
        </>
    );
}