

export default function PortfoliosBlocks(props) {

    return (
        <>
            <div id={props.id} className={`${props.class} -translate-x-56 opacity-0 md:w-1/2 lg:w-1/3 mt-5  group md:px-3.5 `}>
                <div className="rounded-[8px] overflow-hidden relative  after:absolute after:w-full after:h-full after:opacity-0 after:bg-portfoliosAfterBG  after:duration-300 after:top-0 after:left-0  hover:after:opacity-100 after:z-20 ">
                    <a className="w-full rounded-[8px] overflow-hidden" target="_blank" href={`${props.href}`}>
                        <img className="w-full group-hover:scale-125 duration-700" src={props.img} alt="" />
                    </a>
                    <div className=" absolute bottom-10 left-14 " >
                        <h3 className="font-semibold relative delay-300 z-50 translate-y-3 duration-300 opacity-0 group-hover:opacity-100 group-hover:translate-y-0 text-[22px] text-red font-poppins  ">{props.girlsName}
                        </h3>
                        <span className="text-[16px] font-poppins text-white relative  z-50  opacity-0 group-hover:opacity-100  duration-70000">{props.profession}</span>
                    </div>
                </div>
            </div>
        </>
    )

}