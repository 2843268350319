import React, { useEffect } from 'react';
import BigTitles from "../../PropsComponents/bigTitles";
import BlocksDesc from "../../PropsComponents/blocksdesc";

import RedTitles from "../../PropsComponents/redTitles";
import TestSlider from "../../PropsComponents/testSlider";


export default function Testimonials() {
    

    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const slider = document.getElementById('slider');
            if(x+700 > slider.offsetTop){
                slider.style.transition = 'all 1.5s ';
                slider.style.transform = 'translateX(0px)';
                slider.style.opacity = '1'
            }
            
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className="bg-blocks_dark_bg block_padding">
                <div className=" px-[20px] md:px-[40px] max-w-screen-xl space-y-10  mx-auto">
                    <div className="max-lg:w-full lg:w-3/5">
                        <RedTitles redtitle="Testimonials" />
                        <BigTitles bigTitle="What's Clients Say." />
                        <BlocksDesc BlocksDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, egestas. Id fermentum nullam ipsum massa." />
                    </div>
                    <div id='slider' className="w-full -translate-x-56 opacity-0">
                        <TestSlider />
                    </div>
                </div>
            </div>
        </>
    )
}