import React from "react";
import RedTitles from "../../PropsComponents/redTitles";
import BigTitles from "../../PropsComponents/bigTitles";
import BlocksDesc from "../../PropsComponents/blocksdesc";
import Buttons from "../../PropsComponents/buttons";
import SkillsProgres from "../../PropsComponents/skillsProgres";
import { useEffect, useState } from 'react';



const list = [
    {
        title: 'Education',
        content: [
            {
                date: '2020-2021',
                wText: 'Ph.D in Horriblensess',
                gText: ' - Harvard University, Cambridge, MA'
            },
            {
                date: '2018-2019',
                wText: 'Computer Science ',
                gText: ' - Imperialize Technical Institute'

            },
            {
                date: '2016-2018',
                wText: 'Graphic Designer ',
                gText: '- Web Graphy, Los Angeles, CA'
            }
        ]
    },
    {
        title: 'Awards',
        content: [
            {
                date: '2020',
                wText: 'Best Developer ',
                gText: '- University Of Melbourne, NA'
            },
            {
                date: '2019',
                wText: 'Best Writter ',
                gText: '- Online Typodev Soluation Ltd.'

            },
            {
                date: '2007',
                wText: 'Best Freelancer ',
                gText: '- Fiver & Upwork Level 2 & Top Rated'
            }
        ]
    },
]

const progres = [
    {
        title: 'Skills',
        progresContent: [
            {
                progresName: 'Web Design',
                progresPercent: '85%',
                progresValue: '85'
            },
            {
                progresName: 'Mobile App',
                progresPercent: '55%',
                progresValue: '55'
            },
            {
                progresName: 'Illustrator',
                progresPercent: '65%',
                progresValue: '65'
            },
            {
                progresName: 'Photoshop',
                progresPercent: '72%',
                progresValue: '72'
            },
            {
                progresName: 'Motion Graphy',
                progresPercent: '80%',
                progresValue: '80'
            },

        ]
    },
]



export default function About() {

    useEffect(() => {
        const handleScroll = () => {
            const x = document.documentElement.scrollTop;
            const aboutleft = document.getElementById('AboutLeftContent');
            if(x+700 > aboutleft.offsetTop){
                aboutleft.style.transition = 'all 1.5s ';
                aboutleft.style.transform = 'translateX(0px)';
                aboutleft.style.opacity = '1'
            }
            const aboutright = document.getElementById('AboutRightContent');
            if(x+700 > aboutright.offsetTop){
                aboutright.style.transition = 'all 2s ';
                aboutright.style.transform = 'translateX(0px)';
                aboutright.style.opacity = '1'
            }
            const aboutExLeft = document.getElementById('AboutExLeft');
            if(x+700 > aboutExLeft.offsetTop){
                aboutExLeft.style.transition = 'all 1.5s ';
                aboutExLeft.style.transform = 'translateX(0px)';
                aboutExLeft.style.opacity = '1'
            }
            const aboutExMiddle = document.getElementById('AboutExMiddle');
            if(x+700 > aboutExMiddle.offsetTop){
                aboutExMiddle.style.transition = 'all 2s ';
                aboutExMiddle.style.transform = 'translateX(0px)';
                aboutExMiddle.style.opacity = '1';
                aboutExMiddle.style.delay = '1s'
            }
            const aboutExRight = document.getElementById('AboutExRight');
            if(x+700 > aboutExRight.offsetTop){
                aboutExRight.style.transition = 'all 2.5s ';
                aboutExRight.style.transform = 'translateX(0px)';
                aboutExRight.style.opacity = '1'
            }


            
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <>
            <div id="About" className="bg-blocks_light_bg block_padding">

                <div className=" px-[20px] md:px-[40px] max-w-screen-xl  mx-auto ">
                    <div className="">
                        <div className=" w-4/5 lg:w-3/5 space-y-3 pb-10 ">
                            <div>
                                <RedTitles redtitle='About Me' />
                            </div>
                            <div >
                                <BigTitles bigTitle="About Me" />
                            </div>
                            <BlocksDesc BlocksDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, egestas. Id fermentum nullam ipsum massa." />

                        </div>

                        <div className="max-lg:space-y-10 lg:flex">
                            <div id="AboutLeftContent" className=" -translate-x-56 opacity-0 w-full max-md:h-[600px] md:h-[901px] lg:w-1/2 lg:h-auto  ">
                                <div className=" w-full lg:w-[95%] rounded-[8px] h-full bg-About bg-center bg-cover"></div>
                            </div>
                            <div id="AboutRightContent" className=" -translate-x-56 opacity-0 w-full lg:w-1/2 flex flex-col items-end">
                                <div className="lg:w-[90%] space-y-7  ">
                                    <h3 className="text-white font-poppins text-[32px] font-semibold">I'm Bany Astro</h3>
                                    <p className="text-white text-[18px] font-poppins">A Lead <span className="text-red-600">Visual Artist</span> based in <span className="text-red-600">California</span></p>
                                    <p className="border-b pb-5 border-[#cbd3d9] text-nav_text text-[16px] font-poppins">I design and develop services for customers specializing creating stylish, modern websites, web services and online stores. My passion is to design digital user experiences through meaningful interactions. Check out my Portfolio</p>
                                    <div className="">
                                        <h3 className="uppercase text-[20px] font-semibold font-poppins my-5 text-white leading-[1,3]">persnal info</h3>
                                        <div className="max-md:space-y-5 md:flex">
                                            <ul className="md:w-1/2 space-y-5">
                                                <li className="text-nav_text text-[16px] font-medium font-poppins">Name : <span className="text-white">Bany Jara</span></li>
                                                <li className="text-nav_text text-[16px] font-medium font-poppins">Age : <span className="text-white">21 Years</span></li>
                                                <li className="text-nav_text text-[16px] font-medium font-poppins">Nationality : <span className="text-white">USA</span></li>
                                                <li className="text-nav_text text-[16px] font-medium font-poppins">Freelance : <span className="text-white">Available</span></li>
                                            </ul>
                                            <ul className="md:w-1/2 space-y-5">
                                                <li className="text-nav_text text-[16px] font-medium font-poppins">Addres : <span className="text-white">H-400 - Path2UK</span></li>
                                                <li className="text-nav_text text-[16px] font-medium font-poppins">Phone : <span className="text-white">+12345667</span></li>
                                                <li className="text-nav_text text-[16px] font-medium font-poppins">Email : <span className="text-white">yourmail@gmail.com</span></li>
                                                <li className="text-nav_text text-[16px] font-medium font-poppins">Languages : <span className="text-white">French, English</span></li>
                                            </ul>
                                        </div>

                                    </div>
                                    <div className=" group  hover:border-red text-white border-2 border-nav_text rounded-lg px-[35px] py-[10px] text-[19px]  h-[55px]   overflow-hidden  duration-100 w-fit  ">
                                        <Buttons button="Download CV" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="space-y-7  mt-24 pb-5">
                        <h2 className=" text-[30px] md:text-[40px] font-bold font-poppins leading-[1,3] text-white">My Experience</h2>
                        <div className=" max-md:space-y-5 space-x-6 ">
                            <button className="text-white border border-red bg-red py-[5px] px-[35px] text-[16px] font-medium rounded-[6px] leading-8">
                                Education & Skills
                            </button>
                            <button className="text-nav_text border-2 border-nav_text  py-[5px] px-[35px] text-[16px] font-medium rounded-[6px] leading-8">
                                Experience
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col max-lg:space-y-8 lg:flex lg:flex-row ">
                        <div id="AboutExLeft" className='-translate-x-56 opacity-0 w-full lg:w-1/3 lg:p-5 lg:pl-0'>
                            <div className="bg-aboutExBG rounded-[6px] p-[35px] ">

                                <div className='text-white '>
                                    <div className="py-[7px] px-[20px] font-poppins rounded-[4px] bg-aboutExTBG w-fit">{list[0].title}</div>
                                    <ul>
                                        {list[0].content &&
                                            list[0].content.map((itm, ind) => {
                                                return (
                                                    <li className="border-b border-border py-6">
                                                        <div className="text-nav_text text-[18px] font-normal font-poppins">{itm.date}</div>
                                                        <div className="text-white text-[18px] font-medium font-poppins">{itm.wText} <span className="text-nav_text text-[16px] font-normal  ">{itm.gText}</span> </div>
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div id="AboutExMiddle" className="-translate-x-56 opacity-0 w-full lg:w-1/3 lg:p-5 ">
                            <div className="bg-aboutExBG h-full rounded-[6px] p-[35px] text-white">
                                {
                                    progres.map((item, index) => {
                                        return (
                                            <div className='text-white h-full '>
                                                <div className="py-[7px]   px-[20px] font-poppins rounded-[4px] bg-aboutExTBG w-fit">{item.title}</div>
                                                <ul className="max-lg:space-y-5 h-full flex flex-col justify-around pb-5">
                                                    {item.progresContent &&
                                                        item.progresContent.map((itm, ind) => {
                                                            return (
                                                                <li className="">
                                                                    <SkillsProgres progresName={itm.progresName} progresPercent={itm.progresPercent} progresValue={itm.progresValue} />
                                                                </li>
                                                            )
                                                        })}
                                                </ul>
                                            </div>
                                        )
                                    })}

                            </div>

                        </div>
                        <div id="AboutExRight" className="-translate-x-56 opacity-0 w-full  lg:w-1/3 lg:p-4 lg:pr-0 rounded-md">
                            <div className="bg-aboutExBG rounded-[6px] h-full p-[35px] ">

                                <div className='text-white'>
                                    <div className="py-[7px] px-[20px] font-poppins rounded-[4px] bg-aboutExTBG w-fit">{list[1].title}</div>
                                    <ul>
                                        {list[1].content &&
                                            list[1].content.map((itm, ind) => {
                                                return (
                                                    <li className="border-b border-border py-6">
                                                        <div className="text-nav_text text-[18px] font-normal font-poppins">{itm.date}</div>
                                                        <div className="text-white text-[18px] font-medium font-poppins">{itm.wText} <span className="text-nav_text text-[16px] font-normal  ">{itm.gText}</span> </div>
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </div>

                            </div>

                        </div>


                    </div>
                </div>



            </div>
        </>
    )
}