import React, { useEffect } from 'react';
import BigTitles from "../../PropsComponents/bigTitles";
import BlocksDesc from "../../PropsComponents/blocksdesc";
import RedTitles from "../../PropsComponents/redTitles";
import ServiceBlocks from "../../PropsComponents/serviceblocks";
import FirstServiceBlocksImg from "../../../assets/Img/service1.svg";
import SecondServiceBlocksImg from "../../../assets/Img/service2.svg";
import ThirdServiceBlocksImg from "../../../assets/Img/service3.svg";
import ForthServiceBlocksImg from "../../../assets/Img/service4.svg";

export default function Service() {

    const serviceblocks = [
        {id:'FirtsBlock', img:FirstServiceBlocksImg, title:'Motion Art Graphy', class:'pl-0 duration-[1s]'},
        {id:'SecondBlock', img:SecondServiceBlocksImg, title:'Branding & Design', class:'duration-[1.3s]'},
        {id:'ThirdBlock', img:ThirdServiceBlocksImg, title:'Mobile App Design', class:'duration-[1.5s]'},
        {id:'ForthBlock', img:ForthServiceBlocksImg, title:'Ui/Ux Consultancy', class:'pr-0 duration-[1.8s]'},

    ];

    useEffect(() => {
        const handleScroll = () => {
            serviceblocks.forEach((item) => {
                const x = document.documentElement.scrollTop;
                const seviceblock = document.getElementById(item.id);
                if (x + 700 > seviceblock.offsetTop) {
                    seviceblock.classList.add('serviceblock-transition');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div id="Service" className="block_padding bg-aboutExBG">
                <div className=" px-[20px] md:px-[40px] max-w-screen-xl  mx-auto ">
                    <RedTitles redtitle='Service' />
                    <div className="md:w-2/3">
                        <BigTitles bigTitle='I provide wide range of digital services' />
                        <BlocksDesc BlocksDesc='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, egestas. Id fermentum nullam ipsum massa.Motion Art Graphy Learn More'/>
                    </div>
                    <div className="mt-16 flex flex-wrap">
                        {
                            serviceblocks.map((item, index)=>
                            {
                                return <ServiceBlocks id={item.id} img={item.img} title={item.title} class={item.class}/>
                            })
                        }
                    </div>

                </div>
            </div>

        </>
    )
}